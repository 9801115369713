// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.button is-primary {
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 150px;
  height: 40px;
}

.download-table-xls-button {
  background-color: red;
  margin-left: 50px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 150px;
  height: 40px;
}

b{
    font-size: 40px;
    margin-bottom: 20px;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white; 
    z-index: 10; 
}

.table__wrapper {
  display: block;
  max-width: -moz-fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: normal; 
  margin-left: 10px;
  margin-right: 10px;
  overflow: auto;
}

.table__wrapper_Scroll_Log {
  display: block;
  max-width: -moz-fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: normal; 
  margin-left: 10px;
  margin-right: 10px;
  height: 600px;
  overflow: auto;
}

.table__wrapper td {
  word-wrap: break-word; 
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/component/SuratMasuk/suratmasuk.css"],"names":[],"mappings":";;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;IACI,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,MAAM;IACN,uBAAuB;IACvB,WAAW;AACf;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":["\n\n.button is-primary {\n  margin-top: 50px;\n  margin-left: 50px;\n  margin-bottom: 30px;\n  border-radius: 10px;\n  width: 150px;\n  height: 40px;\n}\n\n.download-table-xls-button {\n  background-color: red;\n  margin-left: 50px;\n  margin-bottom: 30px;\n  border-radius: 10px;\n  width: 150px;\n  height: 40px;\n}\n\nb{\n    font-size: 40px;\n    margin-bottom: 20px;\n  }\n\n  .sticky-header {\n    position: sticky;\n    top: 0;\n    background-color: white; \n    z-index: 10; \n}\n\n.table__wrapper {\n  display: block;\n  max-width: -moz-fit-content;\n  margin: 0 auto;\n  overflow-x: auto;\n  white-space: normal; \n  margin-left: 10px;\n  margin-right: 10px;\n  overflow: auto;\n}\n\n.table__wrapper_Scroll_Log {\n  display: block;\n  max-width: -moz-fit-content;\n  margin: 0 auto;\n  overflow-x: auto;\n  white-space: normal; \n  margin-left: 10px;\n  margin-right: 10px;\n  height: 600px;\n  overflow: auto;\n}\n\n.table__wrapper td {\n  word-wrap: break-word; \n  word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
