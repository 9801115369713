import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import Main from '../component/Main/Main';
import SuratKeluar from "../component/SuratKeluar/SuratKeluar";
import axios from "axios";
import Url from "../global.jsx";

const PageSuratKeluar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const checkSessionInterval = setInterval(async () => {
      try {
        const response = await axios.get(`${Url}/suratKeluar`, { withCredentials: true });
        if (response.data.redirect) {
          window.location.href = response.data.url; // Redirect using window.location.href
        }
      } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.redirect) {
          window.location.href = error.response.data.url; // Redirect user to login page
        } else {
          console.error('Error:', error);
        }
      }
    }, 60000); // 1 minute in milliseconds

    return () => clearInterval(checkSessionInterval); // Clean up the interval when the component unmounts
  }, []);

  useEffect(() => {
    if (isError) {
      navigate("https://e-surat.alhasanah.sch.id/");
    }
  }, [isError, navigate]);

  return (
    <Main>
      <SuratKeluar />
    </Main>
  );
};

export default PageSuratKeluar;
