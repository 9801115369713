import React from 'react'
import Main from '../component/Main/Main';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import LogActivityList from '../component/LogActivity/LogActivity.jsx';
import { getMe } from "../features/authSlice";


const LogActivity = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);
  return (
    <Main>
      <LogActivityList/>
    </Main>
  )
}

export default LogActivity