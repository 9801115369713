import React, { useEffect, useState } from "react";
import "./login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser, reset } from "../../features/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [strNama, setNama] = useState("");
  const [strPassword, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();

    if (rememberMe) {
      dispatch(LoginUser({ strNama, strPassword }));

      // Simpan informasi login ke localStorage
      localStorage.setItem("strNama", strNama);
      localStorage.setItem("strPassword", strPassword);
    } else {
      // Hapus informasi login dari localStorage jika checkbox tidak dicentang
      localStorage.removeItem("strNama");
      localStorage.removeItem("strPassword");

      // Jangan melakukan proses login jika checkbox tidak dicentang
      return;
    }
  };

  return (
    <div className="wrapper bg-dark d-flex align-items-center justify-content-center">
      <div className="login">
        <h2 className="title mb-3">E-Surat Yayasan Al Hasanah</h2>
        <form className="needs-validation" onSubmit={Auth}>
          {isError && <p className="has-text-centered">{message}</p>}
          <div className="form-group was-validated mb-2">
            <label htmlFor="text" className="form-label">
              User :
            </label>
            <input
              type="text"
              className="form-control"
              value={strNama}
              onChange={(e) => setNama(e.target.value)}
              required
            ></input>
            <div className="invalid-feedback">Please enter your user</div>
          </div>
          <div className="form-group was-validated mb-2">
            <label htmlFor="password" className="form-label">
              Password :
            </label>
            <input
              type="password"
              className="input"
              value={strPassword}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="******"
              required
            ></input>
            <div className="invalid-feedback">Please enter your password</div>
          </div>
          <div className="form-group form-check mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="check" className="form-check-label">
              No robot
            </label>
          </div>
          <button type="submit" className="btn btn-success block mt-2">
            {isLoading ? "Loading..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
