import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Url from "../../global";
import "./userList.css";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await axios.get(`${Url}/user`, {
      credentials: "include",
    });
    setUsers(response.data);
  };

  const deleteUsers = async (userID) => {
    await axios.delete(`${Url}/user/${userID}`, {
      credentials: "include",
    });
    getUsers();
  };

  return (
    <div className="all">
      <div className="table-responsive">
        <h1 className="title">Pengguna Aps</h1>
        <h2 className="subtitle">List Of Users</h2>
        {/* <Select options={options} onChange={handleFilterInput} value={selectedValue} placeholder="Select a region"/> */}
        <Link to="/users/add" className="button is-primary mb-2">
          Add New Users
        </Link>

        <br />
        <label> Masukan Nama </label>
        <input
          className="search"
          placeholder="Cari Nama Individu"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />

        <div className="table__wrapper_Scroll_Log">
          <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr className="has-text-centered">
                <th className="has-text-centered" width="50px">
                  No
                </th>
                <th className="has-text-centered">Nama</th>
                <th className="has-text-centered">Role</th>
                <th className="has-text-centered">Unit</th>
                <th className="has-text-centered">Action</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter((user) => user.strNama.toLowerCase().includes(query))
                .map((user, index) => (
                  <tr key={user.uuid}>
                    <td className="has-text-centered" width="5px">
                      {index + 1}
                    </td>
                    <td>{user.strNama}</td>
                    <td className="has-text-centered">{user.strRole}</td>
                    <td className="has-text-centered">{user.strUnit}</td>
                    <td className="has-text-centered">
                      <Link
                        to={`/users/edit/${user.uuid}`}
                        className="button is-small is-info"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() => deleteUsers(user.uuid)}
                        className="button is-small is-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
