import React from "react";
// import Back from "./back/back.jsx"
import Heading from "./heading/heading.jsx";
// import img from "./alhasanah.jpg"
import imgImmio from "./immio.jpg";
import "./dashboard.css";
import { useSelector } from "react-redux";

const Dashboard = () => {

  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <section className="about">
        {/* <Back name='Sejarah' title='Yayasan Al Hasanah Bengkulu' cover={img} /> */}
        <div className="container flex mtop">
          <div className="left row">
            <Heading
              title="Aplikasi E-Surat Yayasan Al Hasanah"
              subtitle="Deskripsi Aplikasi Surat"
            />

            <p>
            Selamat datang  <strong>{user && user.strNama}</strong>,e-surat Yayasan Al Hasanah (YAH) merupakan sebuah aplikasi yang bertujuan untuk membantu kegiatan perkantoran secara digital dan mendukung proses pengelolaan administrasi perkantoran khususnya yang berhubungan dengan proses surat menyurat.
            <br/>
            Awal mula terciptanya aplikasi ini untuk meningkatkan kinerja dengan konsep paperless dan efisiensi waktu dalam menyampaikan surat tersebut. Diharapkan aplikasi ini dapat mengoptimalkan berbagai pengelolaan informasi dan data untuk kebutuhan internal YAH sehingga kalancaran birokrasi dapat menjadi penunjang dalam mencapai visi dan misi YAH.
            <br/>
            Di dalam aplikasi ini e-surat.alhasanah.sch.id kita dapat melihat status maupun story perjalanan surat yang diajukan dari staf TU atau staf MNJ menjadi disetujui oleh Sekretaris Eksekutif maupun Ketua Yayasan Al Hasanah Bengkulu
            </p>
            <p>
            Aplikasi ini melibatkan beberapa role pengguna:
              <br />
              1. Staff TU
              <br />
              2. Staff MNJ <br />
              3. Admin
              <br />
              4. Kabid Kepegawaian
              <br />
              5. Kabid Sapras, IT, dan Humas
              <br />
              6. Kabid Pendidikan dan Penjamin Mutu
              <br />
              7. Ketua Yayasan
              <br />
              8. Sekretaris Eksekutif
              <br />
              9. Kepala Sekolah

            </p>
            <br/>
            <br/>
            
            <a 
            target="_blank"
            rel="noreferrer"
            href="https://simpeg.alhasanah.sch.id/">
              <button className="btn2">Jangan lupa mengecek simpeg ya!!</button>
            </a>
          </div>
          <div className="right row">
            <img src={imgImmio} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
