import React from 'react'
// import Navbar from '../Navbar/Navbar'
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from '../Navbar/Sidebar';

const Main = ({children}) => {
  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div className="columns" style={{ minHeight: "auto", width : "auto" }}>
        <div className="column is-2">
          <Sidebar />
        </div>
        <div className="column has-background-light" style={{overflow : "auto"}}>
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Main