// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/ponpes.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper{
    height: 100vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
} 

.login{
    width: 300px;
    height: min-content;
    border-radius: 5px;
    background-color: rgb(237, 237, 237);
    color: black;
    padding: 20px;

}

.title{
    font-size: 10px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/component/Login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yDAA8C;IAC9C,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,oCAAoC;IACpC,YAAY;IACZ,aAAa;;AAEjB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".wrapper{\n    height: 100vh;\n    background-image: url(../../assets/ponpes.jpg);\n    background-position: center;\n} \n\n.login{\n    width: 300px;\n    height: min-content;\n    border-radius: 5px;\n    background-color: rgb(237, 237, 237);\n    color: black;\n    padding: 20px;\n\n}\n\n.title{\n    font-size: 10px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
