// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  margin-bottom: 80px;
  margin-top: 100px;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.about .btn2{
  background-color: #5cc6d0;  
}
.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.left {
  flex-basis: 60%; 
  padding: 0 20px; 
}

.right {
  flex-basis: 40%; 
}

@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
    margin-left: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,UAAU;EACZ;EACA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":[".about {\n  margin-bottom: 80px;\n  margin-top: 100px;\n}\n.about .heading {\n  text-align: left;\n  width: 100%;\n  margin-bottom: 30px;\n}\n\n.about .btn2{\n  background-color: #5cc6d0;  \n}\n.about p {\n  font-size: 15px;\n  padding-right: 30px;\n  margin-bottom: 20px;\n  line-height: 30px;\n}\n\n.left {\n  flex-basis: 60%; \n  padding: 0 20px; \n}\n\n.right {\n  flex-basis: 40%; \n}\n\n@media screen and (max-width: 768px) {\n  .about .container {\n    flex-direction: column;\n  }\n  .about p {\n    padding: 0;\n  }\n  .about .container img {\n    margin-top: 50px;\n    margin-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
