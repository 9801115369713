// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/ponpes.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 991px) {
    .sidebar{
        background-color: rgba(255,255,255, 0.15);
        -webkit-backdrop-filter:blur(10px);
                backdrop-filter:blur(10px);
    }
} 

.sidebar ul{
    padding: 0px !important;
}



`, "",{"version":3,"sources":["webpack://./src/component/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,mDAA0C;IAC1C,+BAA+B;IAC/B,2BAA2B;IAC3B,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI;QACI,yCAAyC;QACzC,kCAA0B;gBAA1B,0BAA0B;IAC9B;AACJ;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".body{\n    background: url('../../assets/ponpes.jpg');\n    background-blend-mode: multiply;\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n}\n\n@media (max-width: 991px) {\n    .sidebar{\n        background-color: rgba(255,255,255, 0.15);\n        backdrop-filter:blur(10px);\n    }\n} \n\n.sidebar ul{\n    padding: 0px !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
