// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect {
  width: 30%;
}

.checkbox-list {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.checkbox-list select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.checkbox-list label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.checkbox-list p {
  margin-top: 8px;
  font-size: 12px;
  color: #777;
}

.button is-primary{
  background-color: aqua;
  margin-left: 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  width: 150px;
  height: 40px;
}

.download-table-xls-button {
  background-color: red;
  font-size: 15px;
  margin-left: 50px;
  border-radius: 20px;
  width: 200px;
  height: 40px;
  text-align: center;
}

b{
  font-size: 40px;
  margin-bottom: 20px;
}

.label{
  margin-right: 2px;
}

.search{
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/component/SuratKeluar/multiSelect.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".multiselect {\n  width: 30%;\n}\n\n.checkbox-list {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  font-size: 14px;\n}\n\n.checkbox-list select {\n  width: 100%;\n  height: 100%;\n  border: none;\n  outline: none;\n  background-color: transparent;\n  cursor: pointer;\n}\n\n.checkbox-list label {\n  display: block;\n  font-weight: bold;\n  margin-bottom: 8px;\n}\n\n.checkbox-list p {\n  margin-top: 8px;\n  font-size: 12px;\n  color: #777;\n}\n\n.button is-primary{\n  background-color: aqua;\n  margin-left: 50px;\n  margin-bottom: 30px;\n  border-radius: 20px;\n  width: 150px;\n  height: 40px;\n}\n\n.download-table-xls-button {\n  background-color: red;\n  font-size: 15px;\n  margin-left: 50px;\n  border-radius: 20px;\n  width: 200px;\n  height: 40px;\n  text-align: center;\n}\n\nb{\n  font-size: 40px;\n  margin-bottom: 20px;\n}\n\n.label{\n  margin-right: 2px;\n}\n\n.search{\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
