import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Navbar.css";
import { LogOut, reset } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as IoIcons from "react-icons/io";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const Logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div className="sidebar d-flex justify-content-between flex-column bg-dark text-white p-4 vh-100">
      <div>
        <a href="https://alhasanah.or.id/">
          {/* <i className='bi bi-bootstrap fs-5 me-2'></i> */}
          <span className="fs-4">Yayasan Al Hasanah</span>
        </a>

        <hr className="text-secondary mt-2" />
        <div>
          <ul className="nav nav-pills flex-column p-0 m-0">
            <li className="nav-item p-1">
              <a href="/dashboard" className="nav-link text-white">
                <i className="bi bi-speedometer me-2 fs-5"></i>
                <span className="fs-5">Dashboard</span>
              </a>
            </li>

            <li className="nav-item p-1">
              <a href="/surat-masuk" className="nav-link text-white">
                <i className="bi bi-envelope me-2 fs-5"></i>
                <span className="fs-5">Surat Masuk ke Yayasan</span>
              </a>
            </li>

            <li className="nav-item p-1">
              <a href="/surat-keluar" className="nav-link text-white">
                <i className="bi bi-envelope-open me-2 fs-5"></i>
                <span className="fs-5">Surat Keluar dari Yayasan</span>
              </a>
            </li>
            {user && user.strRole === "Admin" && (
              <li className="nav-item p-1">
                <a href="/users" className="nav-link text-white">
                  <i className="bi bi-people me-2 fs-5"></i>
                  <span className="fs-5">Pengguna</span>
                </a>
                <a href="/log-activity" className="nav-link text-white">
                  <i className="bi bi-alarm me-2 fs-5"></i>
                  <span className="fs-5">Login Activity</span>
                </a>
              </li>
              
            )}
            
          </ul>
        </div>
      </div>
      <div>
        <ul className="menu-list">
          <li>
            <button
              onClick={Logout}
              className="button is-black justify-content-center display-flex"
            >
              {" "}
              <IoIcons.IoMdLogOut /> Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
