import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login/Login.jsx"
// import Users from "./component/Pages/Users";
import "bootstrap/dist/css/bootstrap.min.css";
import PageAddUser from "../src/Pages/PageAddUser.jsx";
import PageEditUser from "../src/Pages/PageEditUser.jsx";
import PageSuratMasukList from "../src/Pages/PageSuratMasukList.jsx";
import Users from "./Pages/Users.jsx";
import PageAddSuratMasuk from "./Pages/PageAddSuratMasuk.jsx";
import PageEditSuratMasuk from "./Pages/PageEditSuratMasuk.jsx";
import PageSuratKeluar from "./Pages/PageSuratKeluar.jsx";
import PageAddSuratKeluar from "./Pages/PageAddSuratKeluar.jsx";
import PageEditSuratKeluar from "./Pages/PageEditSuratKeluar.jsx";
import PageDashboard from "./Pages/PageDashboard.jsx";
import PageLogActivity from "./Pages/PageLogActivity.jsx";
import PageSuratMasukListACC from "./Pages/PageSuratMasukListACC.jsx";



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/surat-masuk" element={<PageSuratMasukList/>} />
          <Route path="/surat-masuk-acc" element={<PageSuratMasukListACC/>} />
          <Route path="/dashboard" element={<PageDashboard/>} />
          <Route path="/surat-keluar" element={<PageSuratKeluar/>} />
          <Route path="/surat-masuk/add" element={<PageAddSuratMasuk/>} />
          <Route path="/surat-keluar/add" element={<PageAddSuratKeluar/>} />
          <Route path="/surat-masuk/edit/:id" element={<PageEditSuratMasuk/>} />
          <Route path="/surat-keluar/edit/:id" element={<PageEditSuratKeluar/>} />
          <Route path="/users" element={<Users/>} />
          <Route path="/users/add" element={<PageAddUser/>} />
          <Route path="/users/edit/:id" element={<PageEditUser />} />
          <Route path="/log-activity" element={< PageLogActivity/>} />
          <Route path="/" exact={true} element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

//test

export default App;
