import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import axios from "axios"
axios.defaults.withCredentials = true

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);


