import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Url from "../../global.jsx";

//import { useSelector } from "react-redux";

const EditUser = () => {
  //const { pegawai } = useSelector((state) => state.auth);
  const [nama, setNama] = useState("");
  const [msg, setMsg] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [role, setRole] = useState("");
  const [unit, setUnit] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getUserByID = async () => {
      try {
        const response = await axios.get(`${Url}/user/${id}`, {
          credentials: "include",
        });
        setNama(response.data.strNama);
        setRole(response.data.strRole);
        setUnit(response.data.strUnit);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getUserByID();
  }, [id]);

  const updateUsers = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${Url}/user/${id}`,
        {
          strNama: nama,
          strPassword: password,
          confPassword: confPassword,
          strRole: role,
          strUnit: unit,
        },
        { credentials: "include" }
      );
      navigate("/users");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat User</h1>
      <h2 className="subtitle">Edit User</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={updateUsers}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama User</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Nama User"
                    value={nama}
                    onChange={(e) => setNama(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Confirm Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                </div>
              </div>
              {/* {pegawai && (pegawai.strRole === "kepsek" || pegawai.strRole === "user")  && ( */}

              <div className="field">
                <label className="label">Role</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required
                    >
                      <option>--Pilih--</option>
                      <option>Admin</option>
                      <option>Staff TU</option>
                      <option>Staff MNJ</option>
                      <option>Kabid Kepegawaian</option>
                      <option>Kabid Pendidikan dan Penjamin Mutu</option>
                      <option>Kabid Sarpras, IT dan Humas</option>
                      <option>Kepala Sekolah</option>
                      <option>Ketua Yayasan</option>
                      <option>Sekretaris Eksekutif</option>
                      <option>Supervisor Qiraati</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Unit</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                      required
                    >
                      <option>------Pilih------</option>
                      <option>Manajemen</option>
                      <option>Kabid Pendidikan dan Penjamin Mutu</option>
                      <option>Kabid Sarpras, IT dan Humas</option>
                      <option>Manajer Bisnis</option>
                      <option>PAUD IT Al Hasanah 1</option>
                      <option>PAUD IT Al Hasanah 2</option>
                      <option>SDIT Al Hasanah 1</option>
                      <option>SDIT Al Hasanah 2</option>
                      <option>SMPI Al Hasanah</option>
                      <option>SMAIT Al Hasanah</option>
                      <option>SMPI Al Hasanah</option>
                      <option>Pondok Pesantren</option>
                      <option>TPQ SDIT Al Hasanah 1</option>
                      <option>TPQ SMPI Al Hasanah</option>
                      <option>TPQ PAUDIT Al Hasanah 1</option>
                      <option>TPQ SIT Al Hasanah 2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button className="button is-success">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
