import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Url from "../../global";
import axios from "axios";
import * as XLSX from "xlsx";

// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import "./suratmasuk.css";

const SuratMasuk = () => {
  const [suratMasuks, setSuratMasuk] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterTahunAjaran, setFilterTahunAjaran] = useState("");
  const [filterUnit, setFilterUnit] = useState("");
  const [filterJudulSurat, setFilterJudulSurat] = useState("");
  const [filterPerihalSurat, setFilterPerihalSurat] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getSuratMasuk();
  }, []);

  const getSuratMasuk = async () => {
    const response = await axios.get(`${Url}/suratMasuk`);
    setSuratMasuk(response.data);
  };

  const deleteSuratMasuk = async (suratMasukId) => {
    await axios.delete(`${Url}/suratMasuk/${suratMasukId}`, {
      credentials: "include",
    });
    getSuratMasuk();
  };



  const download = (e, strOriginalName) => {
    if (!strOriginalName) {
      // Jika nama asli tidak tersedia, gunakan nama default
      strOriginalName = 'defaultName.pdf';
    }
    // strOriginalName += '.pdf'; // Pastikan ekstensi .pdf
    e.preventDefault();
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", strOriginalName); // Gunakan strOriginalName sebagai nama file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Bebaskan memori yang digunakan untuk URL
      })
      .catch(err => {
        console.error(err);
      });
  };


  const downloadTableAsXLS = () => {
    const table = document.querySelector("table");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "TableSuratMasuk.xlsx");
  };

  // Filter data surat masuk berdasarkan filterStatus dan filterSemester
  const filteredSuratMasuks = suratMasuks.filter((suratMasuk) => {
    const matchStatus = !filterStatus || suratMasuk.strStatus === filterStatus;
    const matchSemester =
      !filterSemester || suratMasuk.strSemester === filterSemester;
    const matchTahunAjaran =
      !filterTahunAjaran || suratMasuk.strTahunAjaran === filterTahunAjaran;
    const matchUnit = !filterUnit || suratMasuk.user.strUnit === filterUnit;
    const matchJudulSurat =
      !filterJudulSurat || suratMasuk.strJudulSurat === filterJudulSurat;
    const matchPerihal =
      !filterPerihalSurat ||
      suratMasuk.strPerihal.toLowerCase().includes(filterPerihalSurat.trim());

    return (
      matchStatus &&
      matchSemester &&
      matchTahunAjaran &&
      matchUnit &&
      matchJudulSurat &&
      matchPerihal
    );
  });


  const sortedSuratMasuks = filteredSuratMasuks.sort((a, b) => {
    const dateA = new Date(a.dateTglSurat);
    const dateB = new Date(b.dateTglSurat);
    return dateA - dateB;
  });


  return (
    <div className="container ">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-bSuratody rounded">
        <div className="row">
          <div
            className="col-sm-12 mt-5 mb-4  text-center"
            style={{ color: "green" }}
          >
            <h2>
              <b>Surat Masuk</b>
            </h2>
          </div>
        </div>

        <div className="container">
          {/* <div className="row"> */}
          {/* <div className="col-sm-6 mt-5"> */}
          {user &&
            (user.strRole === "Admin" ||
              user.strRole === "Staff TU" ||
              user.strRole === "Kepala Sekolah" ||
              user.strRole === "Kabid Sarpras, IT dan Humas" ||
              user.strRole === "Kabid Pendidikan dan Penjamin Mutu") && (
              <Button
                variant="primary"
                href="/surat-masuk/add"
                className="button is-primary mt-5"
              >
                Add New Surat Masuk
              </Button>
            )}

          {/* 
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button mt-5"
            table="table-to-xls"
            filename="Table Surat Masuk"
            sheet="tablexls"
            buttonText="Download as XLS"
          /> */}

          <Button
            onClick={downloadTableAsXLS}
            className="download-table-xls-button mt-5"
          >
            Download Surat Masuk
          </Button>
        </div>

        <div className="modal-footer justify-content-md-end">
          <a href="/surat-masuk" className="btn btn-primary me-2 mt-2">Inbox</a>
          <a href="/surat-masuk-acc" className="btn btn-success mt-2">Aprroved</a>
        </div>

        <br />

        <div className="row">
          {user &&
            (user.strRole === "Admin" ||
              user.strRole === "Staff MNJ" ||
              user.strRole === "Kabid Kepegawaian") && (
              <div className="col-sm-3 mt-5 mb-4 text-gred">
                <label htmlFor="statusFilter">Filter Status:</label>
                <select
                  id="statusFilter"
                  className="form-control"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Pengajuan Surat Staff TU">
                    Pengajuan Surat Staff TU
                  </option>
                  <option value="Pengajuan Kabid ke Ketua Yayasan">
                    Pengajuan Kabid ke Ketua Yayasan
                  </option>
                  <option value="Pengajuan Kabid ke Sekretaris Eksekutif">
                    Pengajuan Kabid ke Sekretaris Eksekutif
                  </option>
                  <option value="Pengajuan Surat Staff MNJ">
                    Pengajuan Surat Staff MNJ
                  </option>
                  <option value="Elsa, Windi dan Septi Follow Up">
                    Elsa, Windi dan Septi Follow Up
                  </option>
                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Kabid Kepegawaian
                  </option>

                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Ketua Yayasan
                  </option>
                  <option value="Ditolak Ketua Yayasan">
                    Ditolak Ketua Yayasan
                  </option>


                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Sekretaris Eksekutif
                  </option>
                  <option value="Ditolak Ketua Yayasan">
                    Ditolak Sekretaris Eksekutif
                  </option>
                </select>
              </div>
            )}

          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="statusSemester">Filter Semester:</label>
            <select
              id="semesterFilter"
              className="form-control"
              value={filterSemester}
              onChange={(e) => setFilterSemester(e.target.value)}
            >
              <option value="">All</option>
              <option value="Semester 1">Semester 1</option>
              <option value="Semester 2">Semester 2</option>
            </select>
          </div>
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="statusSemester">Filter Tahun :</label>
            <select
              id="tahunAjaranFilter"
              className="form-control"
              value={filterTahunAjaran}
              onChange={(e) => setFilterTahunAjaran(e.target.value)}
            >
              <option value="">All</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
            </select>
          </div>
          <br />
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="statusSemester">Filter Unit:</label>
            <select
              id="unitFilter"
              className="form-control"
              value={filterUnit}
              onChange={(e) => setFilterUnit(e.target.value)}
            >
              <option value="">All</option>
              <option value="Manajemen">Manajemen</option>
              <option value="PAUD IT Al Hasanah 1">PAUD IT Al Hasanah 1</option>
              <option value="PAUD IT Al Hasanah 2">PAUD IT Al Hasanah 2</option>
              <option value="SDIT Al Hasanah 1">SDIT Al Hasanah 1</option>
              <option value="SDIT Al Hasanah 2">SDIT Al Hasanah 2</option>
              <option value="SMPI Al Hasanah">SMPI Al Hasanah</option>
              <option value="SMAIT Al Hasanah">SMAIT Al Hasanah</option>
              <option value="SMPI Al Hasanah">SMPI Al Hasanah</option>
              <option value="Pondok Pesantren">Pondok Pesantren</option>
            </select>
          </div>
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="judulSurat">Filter Judul Surat :</label>
            <select
              id="judulSuratFilter"
              className="form-control"
              value={filterJudulSurat}
              onChange={(e) => setFilterJudulSurat(e.target.value)}
            >
              <option value="">All</option>
              <option value="Surat Pernyataan">Surat Pernyataan</option>
              <option value="Surat Pemohonan">Surat Pemohonan</option>
              <option value="Surat Pemesanan Buku">Surat Pemesanan Buku</option>
              <option value="Surat Hasil Microteaching">
                Surat Hasil Microteaching
              </option>
              <option value="Surat Cuti">Surat Cuti</option>
              <option value="Surat Pemberitahuan">Surat Pemberitahuan</option>
              <option value="Surat Undangan">Surat Undangan</option>
              <option value="Surat Laporan">Surat Laporan</option>
              <option value="Surat Teguran">Surat Teguran</option>
              <option value="Surat Biodata Guru dan Karyawan">
                Surat Biodata Guru dan Karyawan
              </option>
              <option value="Surat Jadwal Piket Satpam">
                Surat Jadwal Piket Satpam
              </option>
              <option value="Surat Pinjaman Koperasi Gukar">
                Surat Pinjaman Koperasi Gukar
              </option>
              <option value="Surat Pengunduran Diri dari Yayasan">
                Surat Pengunduran Diri dari Yayasan
              </option>
              <option value="Surat Pengunduran Diri dari Koperasi">
                Surat Pengunduran Diri dari Koperasi
              </option>
              <option value="Surat Rekomendasi Kontrak Guru">
                Surat Rekomendasi Kontrak Guru
              </option>
              <option value="Surat Pengajuan">Surat Pengajuan</option>
              <option value="Surat Pemberitahuan">Surat Pemberitahuan</option>
              <option value="Surat Keputusan">Surat Keputusan</option>
            </select>
          </div>
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="perihal">Filter Perihal :</label>
            <input
              className="search"
              placeholder="Cari Berdasarkan Perihal"
              value={filterPerihalSurat}
              onChange={(e) =>
                setFilterPerihalSurat(e.target.value.toLowerCase())
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="table-responsive ">
          <div className="table__wrapper_Scroll_Log">
            <table
              className="table table-striped table-hover table-bordered"
              id="table-to-xls"
            >
              <thead>
                {user && user.strRole === "Kepala Sekolah" && (
                  <tr>
                    <th className="sticky-header">No </th>
                    <th className="sticky-header">Judul Surat </th>
                    <th className="sticky-header">Nomor Surat</th>
                    <th className="sticky-header">Tanggal Kirim Surat</th>
                    <th className="sticky-header">Tanggal Surat</th>
                    <th className="sticky-header">Semester</th>
                    <th className="sticky-header">Tahun</th>
                    <th className="sticky-header">Perihal</th>
                    <th className="sticky-header">Lampiran</th>
                    <th className="sticky-header">Status</th>
                    <th className="sticky-header">Tujuan</th>
                    <th className="sticky-header">Surat Masuk</th>
                    <th className="sticky-header">Created By</th>
                    <th className="sticky-header">Unit</th>
                    <th className="sticky-header">Komentar Dari Staff MNJ</th>
                    <th className="sticky-header">Tembusan</th>
                    <th className="sticky-header">Actions</th>
                  </tr>
                )}

                {user &&
                  (user.strRole === "Admin" ||
                    user.strRole === "Staff MNJ" ||
                    user.strRole === "Kabid Kepegawaian") &&
                  (
                    <tr>
                      <th className="sticky-header">No </th>
                      <th className="sticky-header">Judul Surat </th>
                      <th className="sticky-header">Nomor Surat</th>
                      <th className="sticky-header">Tanggal Kirim Surat</th>
                      <th className="sticky-header">Tanggal Surat</th>
                      <th className="sticky-header">Semester</th>
                      <th className="sticky-header">Tahun</th>
                      <th className="sticky-header">Perihal</th>
                      <th className="sticky-header">Lampiran</th>
                      <th className="sticky-header">Status</th>
                      <th className="sticky-header">Tujuan</th>
                      <th className="sticky-header">Surat Masuk</th>
                      <th className="sticky-header">Komentar Dari Staff MNJ</th>
                      <th className="sticky-header">
                        Komentar Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">
                        Komentar Ketua Yayasan Al Hasanah
                      </th>
                      <th className="sticky-header">
                        Komentar Ketua Sekretaris Eksekutif
                      </th>
                      <th className="sticky-header">
                        Disposisi Ketua Yayasan
                      </th>
                      <th className="sticky-header">
                        Disposisi Sekretaris Eksekutif
                      </th>

                      <th className="sticky-header">Created By</th>
                      <th className="sticky-header">Unit</th>
                      <th className="sticky-header">
                        Keterangan Dari Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">Tembusan</th>
                      <th className="sticky-header">Actions</th>
                    </tr>
                  )}

                {user &&
                  user.strRole === "Sekretaris Eksekutif"
                  && (
                    <tr>
                      <th className="sticky-header">No </th>
                      <th className="sticky-header">Judul Surat </th>
                      <th className="sticky-header">Nomor Surat</th>
                      <th className="sticky-header">Tanggal Kirim Surat</th>
                      <th className="sticky-header">Tanggal Surat</th>
                      <th className="sticky-header">Semester</th>
                      <th className="sticky-header">Tahun</th>
                      <th className="sticky-header">Perihal</th>
                      <th className="sticky-header">Lampiran</th>
                      <th className="sticky-header">Status</th>
                      <th className="sticky-header">Tujuan</th>
                      <th className="sticky-header">Surat Masuk</th>
                      <th className="sticky-header">Komentar Dari Staff MNJ</th>
                      <th className="sticky-header">
                        Komentar Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">
                        Komentar Sekretaris Eksekutif
                      </th>
                      <th className="sticky-header">
                        Disposisi Sekretaris Eksekutif
                      </th>
                      <th className="sticky-header">Created By</th>
                      <th className="sticky-header">Unit</th>
                      <th className="sticky-header">
                        Keterangan Dari Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">Tembusan</th>
                      <th className="sticky-header">Actions</th>
                    </tr>
                  )}

                {user &&
                  user.strRole === "Ketua Yayasan"
                  && (
                    <tr>
                      <th className="sticky-header">No </th>
                      <th className="sticky-header">Judul Surat </th>
                      <th className="sticky-header">Nomor Surat</th>
                      <th className="sticky-header">Tanggal Kirim Surat</th>
                      <th className="sticky-header">Tanggal Surat</th>
                      <th className="sticky-header">Semester</th>
                      <th className="sticky-header">Tahun</th>
                      <th className="sticky-header">Perihal</th>
                      <th className="sticky-header">Lampiran</th>
                      <th className="sticky-header">Status</th>
                      <th className="sticky-header">Tujuan</th>
                      <th className="sticky-header">Surat Masuk</th>
                      <th className="sticky-header">Komentar Dari Staff MNJ</th>
                      <th className="sticky-header">
                        Komentar Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">
                        Komentar Ketua Yayasan
                      </th>
                      <th className="sticky-header">
                        Disposisi Ketua Yayasan
                      </th>
                      <th className="sticky-header">Created By</th>
                      <th className="sticky-header">Unit</th>
                      <th className="sticky-header">
                        Keterangan Dari Kabid Kepegawaian
                      </th>
                      <th className="sticky-header">Tembusan</th>
                      <th className="sticky-header">Actions</th>
                    </tr>
                  )}


                {user && (user.strRole === "Staff TU" || user.strRole === "Supervisor Qiraati") && (
                  <tr>
                    <th className="sticky-header">No </th>
                    <th className="sticky-header">Judul Surat </th>
                    <th className="sticky-header">Nomor Surat</th>
                    <th className="sticky-header">Tanggal Kirim Surat</th>
                    <th className="sticky-header">Tanggal Surat</th>
                    <th className="sticky-header">Semester</th>
                    <th className="sticky-header">Tahun Ajaran</th>
                    <th className="sticky-header">Perihal</th>
                    <th className="sticky-header">Lampiran</th>
                    <th className="sticky-header">Status</th>
                    <th className="sticky-header">Tujuan</th>
                    <th className="sticky-header">Surat Masuk</th>
                    <th className="sticky-header">Created By</th>
                    <th className="sticky-header">Unit</th>
                    <th className="sticky-header">Komentar Dari Staff MNJ</th>
                    <th className="sticky-header">Tembusan</th>

                    <th className="sticky-header">Actions</th>
                  </tr>
                )}

                {user && user.strRole === "Kabid Sarpras, IT dan Humas" && (
                  <tr>
                    <th className="sticky-header">No </th>
                    <th className="sticky-header">Judul Surat </th>
                    <th className="sticky-header">Nomor Surat</th>
                    <th className="sticky-header">Tanggal Kirim Surat</th>
                    <th className="sticky-header">Tanggal Surat</th>
                    <th className="sticky-header">Semester</th>
                    <th className="sticky-header">Tahun Ajaran</th>
                    <th className="sticky-header">Perihal</th>
                    <th className="sticky-header">Lampiran</th>
                    <th className="sticky-header">Status</th>
                    <th className="sticky-header">Tujuan</th>
                    <th className="sticky-header">Surat Masuk</th>
                    <th className="sticky-header">Created By</th>
                    <th className="sticky-header">Unit</th>
                    <th className="sticky-header">Tembusan</th>
                    <th className="sticky-header">
                      Komentar Kabid Sarpras, IT dan Humas
                    </th>
                    <th className="sticky-header">
                      Komentar Ketua Yayasan
                    </th>
                    <th className="sticky-header">
                      Komentar Sekretaris Eksekutif
                    </th>

                    <th className="sticky-header">Actions</th>
                  </tr>
                )}

                {user &&
                  user.strRole === "Kabid Pendidikan dan Penjamin Mutu" && (
                    <tr>
                      <th className="sticky-header">No </th>
                      <th className="sticky-header">Judul Surat </th>
                      <th className="sticky-header">Nomor Surat</th>
                      <th className="sticky-header">Tanggal Kirim Surat</th>
                      <th className="sticky-header">Tanggal Surat</th>
                      <th className="sticky-header">Semester</th>
                      <th className="sticky-header">Tahun Ajaran</th>
                      <th className="sticky-header">Perihal</th>
                      <th className="sticky-header">Lampiran</th>
                      <th className="sticky-header">Status</th>
                      <th className="sticky-header">Tujuan</th>
                      <th className="sticky-header">Surat Masuk</th>
                      <th className="sticky-header">Created By</th>
                      <th className="sticky-header">Unit</th>
                      <th className="sticky-header">Tembusan</th>
                      <th className="sticky-header">
                        Komentar Kabid Pendidikan dan Penjamin Mutu
                      </th>
                      <th className="sticky-header">
                        Komentar Ketua Yayasan Al Hasanah
                      </th>
                      <th className="sticky-header">
                        Komentar Sekretaris Eksekutif
                      </th>

                      <th className="sticky-header">Actions</th>
                    </tr>
                  )}
              </thead>

              {user && user.strRole === "Admin" && (
                <tbody>
                  {sortedSuratMasuks.map((suratMasuk, index) =>
                    suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                      suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Ketua Yayasan" ||
                      suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff MNJ" ||
                      suratMasuk.strStatus === "Elsa, Windi dan Septi Follow Up" ||
                      suratMasuk.strStatus === "Revisi Kabid Kepegawaian" ||
                      suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                      suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                      suratMasuk.strStatus === "Revisi Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>

                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>

                        <td>{suratMasuk.strKomentarStaffMNJ}</td>
                        <td>{suratMasuk.strKomentar}</td>
                        <td>{suratMasuk.strKomentarKetua}</td>
                        <td>{suratMasuk.strKomentarSE}</td>
                        <td>{suratMasuk.strDisposisi}</td>
                        <td>{suratMasuk.strDisposisiSE}</td>
                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKeterangan}</td>
                        <td>{suratMasuk.strTembusan}</td>

                        <td>
                          <a
                            href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: "7px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                          <a
                            href="/surat-masuk"
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            style={{ color: "red" }}
                            onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Staff MNJ" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                      suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Ketua Yayasan" ||
                      suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff MNJ" ||
                      suratMasuk.strStatus === "Elsa, Windi dan Septi Follow Up" ||
                      suratMasuk.strStatus === "Revisi Kabid Kepegawaian" ||
                      suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                      suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                      suratMasuk.strStatus === "Revisi Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>


                        <td>{suratMasuk.strKomentarStaffMNJ}</td>
                        <td>{suratMasuk.strKomentar}</td>
                        <td>{suratMasuk.strKomentarKetua}</td>
                        <td>{suratMasuk.strKomentarSE}</td>
                        <td>{suratMasuk.strDisposisi}</td>
                        <td>{suratMasuk.strDisposisiSE}</td>
                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKeterangan}</td>

                        <td>{suratMasuk.strTembusan}</td>
                        <td>
                          {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                          <a
                            href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: "7px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                          <a
                            href="/surat-masuk"
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            style={{ color: "red" }}
                            onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Staff TU" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    // suratMasuk.strStatus === "ACC Kabid Kepegawaian" ||
                    //   suratMasuk.strStatus ===
                    //   "ACC Kabid Sarpras, IT dan Humas" ||
                    //   suratMasuk.strStatus ===
                    //   "ACC Kabid Pendidikan dan Penjamin Mutu" ||
                    suratMasuk.strStatus ===
                      "Ditolak Kabid Sarpras, IT dan Humas" ||
                      suratMasuk.strStatus ===
                      "Ditolak Kabid Pendidikan dan Penjamin Mutu" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                      suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                      suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Revisi Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                      suratMasuk.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>


                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKomentarStaffMNJ}</td>
                        <td>{suratMasuk.strTembusan}</td>
                        <td>-</td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Supervisor Qiraati" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    // suratMasuk.strStatus === "ACC Kabid Kepegawaian" ||
                    //   suratMasuk.strStatus ===
                    //   "ACC Kabid Sarpras, IT dan Humas" ||
                    //   suratMasuk.strStatus ===
                    //   "ACC Kabid Pendidikan dan Penjamin Mutu" ||
                    suratMasuk.strStatus ===
                      "Ditolak Kabid Sarpras, IT dan Humas" ||
                      suratMasuk.strStatus ===
                      "Ditolak Kabid Pendidikan dan Penjamin Mutu" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                      suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                      suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Revisi Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                      suratMasuk.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>
                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>


                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKomentarStaffMNJ}</td>
                        <td>{suratMasuk.strTembusan}</td>
                        <td>-</td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}


              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Sri Wahyuni, S.Pd.,Gr." && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "SDIT Al Hasanah 1" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          // suratMasuk.strStatus === "Revisi Kabid Kepegawaian" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>

                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>



                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Rian Ramadhanu, S.Pd.,Gr" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "SDIT Al Hasanah 2" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>

                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>



                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Zulfikar, M.Pd" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "SMPI Al Hasanah" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>

                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>



                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Syamsidar,S.Pd.I" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "PAUD IT Al Hasanah 1" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>

                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>


                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Deri Fachri Hasymi, S.Pi" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "Pondok Pesantren" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>
                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>

                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Muhammad Barid, M.Pd.I" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "Pondok Pesantren" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>

                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>


                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user &&
                user.strRole === "Kepala Sekolah" &&
                user.strNama === "Aulia Rahmi" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) =>
                      suratMasuk.user.strUnit === "PAUD IT Al Hasanah 2" &&
                        (suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                          suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                          // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                          suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                          // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                          suratMasuk.strStatus ===
                          "Revisi Sekretaris Eksekutif" ||
                          suratMasuk.strStatus === "Ditolak Ketua Yayasan" ||
                          suratMasuk.strStatus ===
                          "Ditolak Sekretaris Eksekutif") ? (
                        <tr key={[suratMasuk.uuid]}>
                          <td>{suratMasuk.nomor}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>
                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>



                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>

                          <td>{suratMasuk.strKomentarStaffMNJ}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>-</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

              {user && user.strRole === "Ketua Yayasan" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    (suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Ketua Yayasan" ||
                      suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                      suratMasuk.strStatus === "Revisi Ketua Yayasan" ||
                      suratMasuk.strStatus === "Ditolak Ketua Yayasan") && suratMasuk.strTujuan === "Ketua Yayasan" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>

                        <td>-</td>
                        <td>{suratMasuk.strKomentar}</td>
                        <td>{suratMasuk.strKomentarKetua}</td>
                        <td>{suratMasuk.strDisposisi}</td>
                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKeterangan}</td>
                        <td>{suratMasuk.strTembusan}</td>
                        <td>
                          {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                          <a
                            href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: "7px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                          <a
                            href="/surat-masuk"
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            style={{ color: "red" }}
                            onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Sekretaris Eksekutif" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    (suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                      suratMasuk.strStatus === "Revisi Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Ditolak Sekretaris Eksekutif") && suratMasuk.strTujuan === "Sekretaris Eksekutif" ? (
                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>

                        <td>-</td>
                        <td>{suratMasuk.strKomentar}</td>
                        <td>{suratMasuk.strKomentarSE}</td>
                        <td>{suratMasuk.strDisposisiSE}</td>
                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKeterangan}</td>
                        <td>{suratMasuk.strTembusan}</td>
                        <td>
                          {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                          <a
                            href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: "7px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                          <a
                            href="/surat-masuk"
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            style={{ color: "red" }}
                            onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Kabid Kepegawaian" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) =>
                    suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Ketua Yayasan" ||
                      suratMasuk.strStatus ===
                      "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff MNJ" ||
                      suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                      // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                      // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Elsa, Windi dan Septi Follow Up" ||
                      suratMasuk.strStatus === "Revisi Kabid Kepegawaian" ||
                      suratMasuk.strTembusan === "Kabid Kepegawaian" ? (


                      <tr key={[suratMasuk.uuid]}>
                        <td>{suratMasuk.nomor}</td>
                        <td>{suratMasuk.strJudulSurat}</td>
                        <td>{suratMasuk.strNoSurat}</td>
                        <td>
                          {moment(suratMasuk.dateTglSurat).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {suratMasuk.dateTglSuratFix !== null
                            ? moment(suratMasuk.dateTglSuratFix).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </td>
                        <td>{suratMasuk.strSemester}</td>
                        <td>{suratMasuk.strTahunAjaran}</td>
                        <td>{suratMasuk.strPerihal}</td>
                        <td>{suratMasuk.strLampiran}</td>
                        <td>{suratMasuk.strStatus}</td>
                        <td>{suratMasuk.strTujuan}</td>

                        <td>
                          <a
                            href={suratMasuk.url}
                            onClick={(e) => download(e, suratMasuk.strOriginalName)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: "7px" }}
                          >
                            <i className="fa fa-download" />
                            Surat Masuk
                          </a>
                        </td>



                        <td>-</td>
                        <td>{suratMasuk.strKomentar}</td>
                        <td>{suratMasuk.strKomentarKetua}</td>
                        <td>{suratMasuk.strKomentarSE}</td>
                        <td>{suratMasuk.strDisposisi}</td>
                        <td>{suratMasuk.strDisposisiSE}</td>
                        <td>{suratMasuk.user.strNama}</td>
                        <td>{suratMasuk.user.strUnit}</td>
                        <td>{suratMasuk.strKeterangan}</td>
                        <td>{suratMasuk.strTembusan}</td>
                        <td>
                          <a
                            href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            style={{ marginRight: "7px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                          <a
                            href="/surat-masuk"
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            style={{ color: "red" }}
                            onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              )}

              {user && user.strRole === "Kabid Sarpras, IT dan Humas" && (
                <tbody>
                  {filteredSuratMasuks.map((suratMasuk, index) => {
                    const isConditionMet =
                      // suratMasuk.strStatus ===
                      // "ACC Kabid Sarpras, IT dan Humas" ||
                      suratMasuk.strStatus ===
                      "Ditolak Kabid Sarpras, IT dan Humas" ||
                      suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                      suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                      // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                      // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                      suratMasuk.strStatus === "Elsa, Windi dan Septi Follow Up" ||
                      suratMasuk.strStatus === "Revisi Kabid Kepegawaian"
                      ;

                    const isTujuanMet =
                      suratMasuk.strTujuan === "Kabid Sarpras, IT dan Humas";
                    const isPribadi = suratMasuk.user.strNama === "	Irma Adespa B";
                    const isTembusan = suratMasuk.strTembusan === "Kabid Sarpras, IT dan Humas";

                    // Condition 1: Check if `strTujuan` is matched
                    if (isConditionMet && isTujuanMet) {
                      return (
                        <tr key={suratMasuk.uuid}>
                          <td>{index + 1}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>
                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>


                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>{suratMasuk.strKomentar}</td>
                          <td>{suratMasuk.strKomentarKetua}</td>
                          <td>{suratMasuk.strKomentarSE}</td>
                          <td>
                            <a
                              href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-masuk"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratMasuk(suratMasuk.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      );
                    }

                    // Condition 2: Check only status without `strTujuan`
                    if (isConditionMet && isPribadi) {
                      return (
                        <tr key={suratMasuk.uuid}>
                          <td>{index + 1}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>
                          <td>
                            <a
                              href={suratMasuk.url}
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>


                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>{suratMasuk.strKomentar}</td>
                          <td>{suratMasuk.strKomentarKetua}</td>
                          <td>{suratMasuk.strKomentarSE}</td>
                          <td>

                            -
                          </td>
                        </tr>
                      );
                    }

                    // Condition 3: Check only status without `strTembusan`
                    if (isConditionMet && isTembusan) {
                      return (
                        <tr key={suratMasuk.uuid}>
                          <td>{index + 1}</td>
                          <td>{suratMasuk.strJudulSurat}</td>
                          <td>{suratMasuk.strNoSurat}</td>
                          <td>
                            {moment(suratMasuk.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratMasuk.dateTglSuratFix !== null
                              ? moment(suratMasuk.dateTglSuratFix).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </td>
                          <td>{suratMasuk.strSemester}</td>
                          <td>{suratMasuk.strTahunAjaran}</td>
                          <td>{suratMasuk.strPerihal}</td>
                          <td>{suratMasuk.strLampiran}</td>
                          <td>{suratMasuk.strStatus}</td>
                          <td>{suratMasuk.strTujuan}</td>
                          <td>
                            <a
                              href={suratMasuk.url}
                              download
                              onClick={(e) => download(e, suratMasuk.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Masuk
                            </a>
                          </td>

                          <td>{suratMasuk.user.strNama}</td>
                          <td>{suratMasuk.user.strUnit}</td>
                          <td>{suratMasuk.strTembusan}</td>
                          <td>{suratMasuk.strKomentar}</td>
                          <td>{suratMasuk.strKomentarKetua}</td>
                          <td>{suratMasuk.strKomentarSE}</td>
                          <td>
                            {/* <a href={`/surat-masuk/edit/${suratMasuk.uuid}`} className="edit" title="Edit" data-toggle="tooltip">
                <FontAwesomeIcon icon={faEdit} />
              </a>
              <a href="/surat-masuk" className="delete" title="Delete" data-toggle="tooltip" style={{ color: "red" }} onClick={() => deleteSuratMasuk(suratMasuk.uuid)}>
                <FontAwesomeIcon icon={faTrash} />
              </a> */}
                            -
                          </td>
                        </tr>
                      );
                    }

                    return null;
                  })}
                </tbody>
              )}

              {user &&
                user.strRole === "Kabid Pendidikan dan Penjamin Mutu" && (
                  <tbody>
                    {filteredSuratMasuks.map((suratMasuk, index) => {
                      const isConditionMet =
                        // suratMasuk.strStatus ===
                        // "ACC Kabid Pendidikan dan Penjamin Mutu" ||
                        suratMasuk.strStatus ===
                        "Ditolak Kabid Pendidikan dan Penjamin Mutu" ||
                        suratMasuk.strStatus === "Pengajuan Surat Staff TU" ||
                        suratMasuk.strStatus === "Pengajuan Surat Kepala Sekolah" ||
                        // suratMasuk.strStatus === "ACC Ketua Yayasan" ||
                        // suratMasuk.strStatus === "ACC Sekretaris Eksekutif" ||
                        suratMasuk.strStatus === "Elsa, Windi dan Septi Follow Up" ||
                        suratMasuk.strStatus === "Revisi Kabid Kepegawaian";

                      const isTujuanMet =
                        suratMasuk.strTujuan ===
                        "Kabid Pendidikan dan Penjamin Mutu";
                      const isPribadi = suratMasuk.user.strNama === "Penty Minarti";
                      const isTembusan = suratMasuk.strTembusan === "Kabid Pendidikan dan Penjamin Mutu";


                      // Condition 1: Check if `strTujuan` is matched
                      if (isConditionMet && isTujuanMet) {
                        return (
                          <tr key={suratMasuk.uuid}>
                            <td>{index + 1}</td>
                            <td>{suratMasuk.strJudulSurat}</td>
                            <td>{suratMasuk.strNoSurat}</td>
                            <td>
                              {moment(suratMasuk.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratMasuk.dateTglSuratFix !== null
                                ? moment(suratMasuk.dateTglSuratFix).format(
                                  "DD/MM/YYYY"
                                )
                                : "-"}
                            </td>
                            <td>{suratMasuk.strSemester}</td>
                            <td>{suratMasuk.strTahunAjaran}</td>
                            <td>{suratMasuk.strPerihal}</td>
                            <td>{suratMasuk.strLampiran}</td>
                            <td>{suratMasuk.strStatus}</td>
                            <td>{suratMasuk.strTujuan}</td>
                            <td>
                              <a
                                href={suratMasuk.url}
                                onClick={(e) => download(e, suratMasuk.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Masuk
                              </a>
                            </td>


                            <td>{suratMasuk.user.strNama}</td>
                            <td>{suratMasuk.user.strUnit}</td>
                            <td>{suratMasuk.strTembusan}</td>
                            <td>{suratMasuk.strKomentar}</td>
                            <td>{suratMasuk.strKomentarKetua}</td>
                            <td>{suratMasuk.strKomentarSE}</td>
                            <td>
                              <a
                                href={`/surat-masuk/edit/${suratMasuk.uuid}`}
                                className="edit"
                                title="Edit"
                                data-toggle="tooltip"
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </a>
                              <a
                                href="/surat-masuk"
                                className="delete"
                                title="Delete"
                                data-toggle="tooltip"
                                style={{ color: "red" }}
                                onClick={() =>
                                  deleteSuratMasuk(suratMasuk.uuid)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </a>
                            </td>
                          </tr>
                        );
                      }

                      // Condition 2: Check only status without `strTujuan`
                      if (isConditionMet && isPribadi) {
                        return (
                          <tr key={suratMasuk.uuid}>
                            <td>{index + 1}</td>
                            <td>{suratMasuk.strJudulSurat}</td>
                            <td>{suratMasuk.strNoSurat}</td>
                            <td>
                              {moment(suratMasuk.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratMasuk.dateTglSuratFix !== null
                                ? moment(suratMasuk.dateTglSuratFix).format(
                                  "DD/MM/YYYY"
                                )
                                : "-"}
                            </td>
                            <td>{suratMasuk.strSemester}</td>
                            <td>{suratMasuk.strTahunAjaran}</td>
                            <td>{suratMasuk.strPerihal}</td>
                            <td>{suratMasuk.strLampiran}</td>
                            <td>{suratMasuk.strStatus}</td>
                            <td>{suratMasuk.strTujuan}</td>
                            <td>
                              <a
                                href={suratMasuk.url}
                                onClick={(e) => download(e, suratMasuk.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Masuk
                              </a>
                            </td>


                            <td>{suratMasuk.user.strNama}</td>
                            <td>{suratMasuk.user.strUnit}</td>
                            <td>{suratMasuk.strTembusan}</td>
                            <td>{suratMasuk.strKomentar}</td>
                            <td>{suratMasuk.strKomentarKetua}</td>
                            <td>{suratMasuk.strKomentarSE}</td>
                            <td>
                              {/* <a href={`/surat-masuk/edit/${suratMasuk.uuid}`} className="edit" title="Edit" data-toggle="tooltip">
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a href="/surat-masuk" className="delete" title="Delete" data-toggle="tooltip" style={{ color: "red" }} onClick={() => deleteSuratMasuk(suratMasuk.uuid)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </a> */}
                              -
                            </td>
                          </tr>
                        );
                      }

                      // Condition 3: Check only status without `strTembusan`
                      if (isConditionMet && isTembusan) {
                        return (
                          <tr key={suratMasuk.uuid}>
                            <td>{index + 1}</td>
                            <td>{suratMasuk.strJudulSurat}</td>
                            <td>{suratMasuk.strNoSurat}</td>
                            <td>
                              {moment(suratMasuk.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratMasuk.dateTglSuratFix !== null
                                ? moment(suratMasuk.dateTglSuratFix).format(
                                  "DD/MM/YYYY"
                                )
                                : "-"}
                            </td>
                            <td>{suratMasuk.strSemester}</td>
                            <td>{suratMasuk.strTahunAjaran}</td>
                            <td>{suratMasuk.strPerihal}</td>
                            <td>{suratMasuk.strLampiran}</td>
                            <td>{suratMasuk.strStatus}</td>
                            <td>{suratMasuk.strTujuan}</td>
                            <td>
                              <a
                                href={suratMasuk.url}
                                onClick={(e) => download(e, suratMasuk.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Masuk
                              </a>
                            </td>


                            <td>{suratMasuk.user.strNama}</td>
                            <td>{suratMasuk.user.strUnit}</td>
                            <td>{suratMasuk.strTembusan}</td>
                            <td>{suratMasuk.strKomentar}</td>
                            <td>{suratMasuk.strKomentarKetua}</td>
                            <td>{suratMasuk.strKomentarSE}</td>
                            <td>
                              {/* <a href={`/surat-masuk/edit/${suratMasuk.uuid}`} className="edit" title="Edit" data-toggle="tooltip">
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a href="/surat-masuk" className="delete" title="Delete" data-toggle="tooltip" style={{ color: "red" }} onClick={() => deleteSuratMasuk(suratMasuk.uuid)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </a> */}
                              -
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuratMasuk;
