import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getMe } from "../features/authSlice";
import Main from '../component/Main/Main';
import AddSuratKeluar from '../component/SuratKeluar/AddSuratKeluar';


const PageAddSuratKeluar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);
  
    useEffect(() => {
      dispatch(getMe());
    }, [dispatch]);
  
    useEffect(() => {
      if (isError) {
        navigate("/");
      }
    }, [isError, navigate]);
    return (
      <Main>
        <AddSuratKeluar/>
      </Main>
   
    )
}

export default PageAddSuratKeluar