import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Url from "../../global";
import axios from "axios";
import moment from "moment";
import "./multiSelect.css";
import * as XLSX from 'xlsx';


const SuratKeluar = () => {
  const [suratKeluars, setSuratKeluar] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterTahunAjaran, setFilterTahunAjaran] = useState("");
  const [filterJudulSurat, setFilterJudulSurat] = useState("");
  const [filterPerihalSurat, setFilterPerihalSurat] = useState("");
  const [filterTujuan, setFilterTujuan] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getSuratKeluar();
  }, []);

  const getSuratKeluar = async () => {
    const response = await axios.get(`${Url}/suratKeluar`);
    setSuratKeluar(response.data);
  };

  const deleteSuratKeluar = async (suratKeluarId) => {
    await axios.delete(`${Url}/suratKeluar/${suratKeluarId}`, {
      credentials: "include",
    });
    getSuratKeluar();
  };



  const download = (e, strOriginalName) => {
    if (!strOriginalName) {
      // Jika nama asli tidak tersedia, gunakan nama default
      strOriginalName = 'defaultName.pdf';

    }

    e.preventDefault();
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", strOriginalName); // Gunakan strOriginalName sebagai nama file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Bebaskan memori yang digunakan untuk URL
      })
      .catch(err => {
        console.error(err);
      });
  };


  const downloadTableAsXLS = () => {
    const table = document.querySelector("table");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "TableSuratKeluar.xlsx");
  }


  // Filter data surat keluar berdasarkan filterStatus dan filterSemester
  const filteredSuratKeluars = suratKeluars.filter((suratKeluar) => {
    const matchStatus = !filterStatus || suratKeluar.strStatus === filterStatus;
    const matchSemester =
      !filterSemester || suratKeluar.strSemester === filterSemester;
    const matchTahunAjaran =
      !filterTahunAjaran || suratKeluar.strTahunAjaran === filterTahunAjaran;
    const matchJudulSurat =
      !filterJudulSurat || suratKeluar.strJudulSurat === filterJudulSurat;
    const matchPerihal =
      !filterPerihalSurat ||
      suratKeluar.strPerihal.toLowerCase().includes(filterPerihalSurat.trim());
    const matchTujuan =
      !filterTujuan ||
      suratKeluar.strTujuan.toLowerCase().includes(filterTujuan.trim());

    return (
      matchStatus &&
      matchSemester &&
      matchTahunAjaran &&
      matchJudulSurat &&
      matchPerihal &&
      matchTujuan
    );
  });

  return (
    <div className="container ">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-bSuratody rounded">
        <div className="row">
          <div
            className="col-sm-12 mt-5 mb-4 text-center"
            style={{ color: "green" }}
          >
            <h2>
              <b>Surat Keluar</b>
            </h2>
          </div>
        </div>

        <div className="container">
          {/* <div className="row"> */}
          {/* <div className="col-sm-12 mt-5 text-right"> */}
          {user &&
            (user.strRole === "Admin" || user.strRole === "Staff MNJ") && (
              <Button
                variant="primary"
                href="/surat-keluar/add"
                className="button is-primary mt-5"
              >
                Add New Surat Keluar
              </Button>
            )}

          <Button onClick={downloadTableAsXLS} className="download-table-xls-button mt-5">Download Surat Keluar</Button>


          {/* </div> */}
        </div>
        <div className="row">
          {user &&
            (user.strRole === "Admin" ||
              user.strRole === "Staff MNJ" ||
              user.strRole === "Kabid Kepegawaian") && (
              <div className="col-sm-3 mt-5 mb-4 text-gred">
                <label htmlFor="statusFilter">Filter Status:</label>
                <select
                  id="statusFilter"
                  className="form-control"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Pengajuan Surat Staff MNJ">
                    Pengajuan Surat Staff MNJ
                  </option>
                  <option value="Pengajuan Kabid ke Ketua Yayasan">
                    Pengajuan Kabid ke Ketua Yayasan
                  </option>
                  <option value="Pengajuan Kabid ke Sekretaris Eksekutif">
                    Pengajuan Kabid ke Sekretaris Eksekutif
                  </option>
                  <option value="Pengajuan Surat Staff MNJ">
                    Pengajuan Surat Staff MNJ
                  </option>
                  <option value="Elsa, Windi dan Septi Follow Up">
                    Elsa, Windi dan Septi Follow Up
                  </option>
                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Kabid Kepegawaian
                  </option>

                  <option value="ACC Ketua Yayasan">ACC Ketua Yayasan</option>
                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Ketua Yayasan
                  </option>
                  <option value="Ditolak Ketua Yayasan">
                    Ditolak Ketua Yayasan
                  </option>

                  <option value="ACC Sekretaris Eksekutif">
                    ACC Sekretaris Eksekutif
                  </option>
                  <option value="Revisi Kabid Kepegawaian">
                    Revisi Sekretaris Eksekutif
                  </option>
                  <option value="Ditolak Ketua Yayasan">
                    Ditolak Sekretaris Eksekutif
                  </option>
                </select>
              </div>
            )}

          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="statusSemester">Filter Semester:</label>
            <select
              id="semesterFilter"
              className="form-control"
              value={filterSemester}
              onChange={(e) => setFilterSemester(e.target.value)}
            >
              <option value="">All</option>
              <option value="Semester 1">Semester 1</option>
              <option value="Semester 2">Semester 2</option>
            </select>
          </div>

          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="statusSemester">Filter Tahun Ajaran:</label>
            <select
              id="tahunAjaranFilter"
              className="form-control"
              value={filterTahunAjaran}
              onChange={(e) => setFilterTahunAjaran(e.target.value)}
            >
              <option value="">All</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
            </select>
          </div>
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="judulSurat">Filter Judul Surat :</label>
            <select
              id="judulSuratFilter"
              className="form-control"
              value={filterJudulSurat}
              onChange={(e) => setFilterJudulSurat(e.target.value)}
            >
              <option value="">All</option>
              <option value="Surat Pernyataan">Surat Pernyataan</option>
              <option value="Surat Pemohonan">Surat Pemohonan</option>
              <option value="Surat Undangan">Surat Undangan</option>
              <option value="Surat Kuasa">Surat Kuasa</option>
              <option value="Surat Pengalaman Kerja">Surat Pengalaman Kerja</option>
              <option value="Surat Peringantan">Surat Peringantan</option>
              <option value="Surat Agenda Sekolah">Surat Agenda Sekolah</option>
              <option value="Surat Perpanjangan KTA">Surat Perpanjangan KTA</option>
              <option value="Surat Piutang">Surat Piutang</option>
              <option value="Surat Study Banding">Surat Study Banding</option>
              <option value="Surat Pemberitahuan">Surat Pemberitahuan</option>
              <option value="Surat Kode Etik">Surat Kode Etik</option>
              <option value="Surat SOP">Surat SOP</option>
              <option value="Surat Keterangan">Surat Keterangan</option>
              <option value="Surat Wawancara PPDB, lembar komitmen dan lain - lain.">Surat Wawancara PPDB, lembar komitmen dan lain - lain.</option>
              <option value="Surat Pinjaman">Surat Pinjaman</option>
              <option value="Surat Sertifikat Pelatihan">Surat Sertifikat Pelatihan</option>
              <option>Surat Keputusan</option>
            </select>
          </div>

          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="perihal">Filter Perihal :</label>
            <input
              className="search"
              placeholder="Cari Berdasarkan Perihal"
              value={filterPerihalSurat}
              onChange={(e) =>
                setFilterPerihalSurat(e.target.value.toLowerCase())
              }
            />
          </div>

          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <label htmlFor="tujuan">Filter Tujuan :</label>
            <input
              className="search"
              placeholder="Cari Berdasarkan Tujuan"
              value={filterTujuan}
              onChange={(e) => setFilterTujuan(e.target.value.toLowerCase())}
            />
          </div>
        </div>

        <div className="row">
          <div className="table-responsive ">
            <div className="table__wrapper_Scroll_Log">
              <table
                className="table table-striped table-hover table-bordered"
                id="table-to-xls"
              >
                <thead>
                  <tr>
                    <th className="sticky-header">Judul Surat </th>
                    <th className="sticky-header">Nomor Surat</th>
                    <th className="sticky-header">Tanggal Kirim Surat</th>
                    <th className="sticky-header">Tanggal Surat</th>
                    <th className="sticky-header">Semester</th>
                    <th className="sticky-header">Tahun</th>
                    <th className="sticky-header">Perihal</th>
                    <th className="sticky-header">Lampiran</th>
                    <th className="sticky-header">Status</th>
                    <th className="sticky-header">Tujuan</th>
                    <th className="sticky-header">Surat Keluar</th>
                    <th className="sticky-header">Komentar Kabid Kepegawaian</th>
                    <th className="sticky-header">Komentar Ketua Yayasan</th>
                    <th className="sticky-header">Komentar Sekretaris Eksekutif</th>
                    <th className="sticky-header">Tembusan</th>
                    <th className="sticky-header">Created By</th>
                    <th className="sticky-header">Actions</th>
                  </tr>
                </thead>
                {user && user.strRole === "Admin" && (
                  <tbody>
                    {filteredSuratKeluars.map((suratKeluar, index) =>
                      suratKeluar.strStatus === "Pengajuan Surat Staff MNJ" ||
                        suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Ketua Yayasan" ||
                        suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Kabid Kepegawaian" ||
                        suratKeluar.strStatus === "ACC Kabid Kepegawaian" ||
                        suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                        suratKeluar.strStatus === "Revisi Ketua Yayasan" ||
                        suratKeluar.strStatus === "ACC Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Ditolak Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Ditolak Ketua Yayasan" ? (
                        <tr key={[suratKeluar.uuid]}>
                          <td>{suratKeluar.strJudulSurat}</td>
                          <td>{suratKeluar.strNoSurat}</td>
                          <td>
                            {moment(suratKeluar.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                          </td>
                          <td>{suratKeluar.strSemester}</td>
                          <td>{suratKeluar.strTahunAjaran}</td>
                          <td>{suratKeluar.strPerihal}</td>
                          <td>{suratKeluar.strLampiran}</td>
                          <td>{suratKeluar.strStatus}</td>
                          <td>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul>
                          </td>

                          <td>
                            <a
                              href={suratKeluar.url}
                              onClick={(e) => download(e, suratKeluar.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Keluar
                            </a>
                          </td>

                          <td>{suratKeluar.strKomentar}</td>
                          <td>{suratKeluar.strKomentarKetua}</td>
                          <td>{suratKeluar.strKomentarSE}</td>
                          <td>{suratKeluar.strTembusan}</td>
                          <td>{suratKeluar.user.strNama}</td>
                          <td>
                            {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                            <a
                              href={`/surat-keluar/edit/${suratKeluar.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-keluar"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratKeluar(suratKeluar.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

                {user && user.strRole === "Staff MNJ" && (
                  <tbody>
                    {filteredSuratKeluars.map((suratKeluar, index) =>
                      suratKeluar.strStatus === "Pengajuan Surat Staff MNJ" ||
                        suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Ketua Yayasan" ||
                        suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Kabid Kepegawaian" ||
                        suratKeluar.strStatus === "ACC Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "ACC Kabid Kepegawaian" ||
                        suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                        suratKeluar.strStatus === "Revisi Ketua Yayasan" ||
                        suratKeluar.strStatus === "Revisi Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Ketua Yayasan" ||
                        suratKeluar.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                        <tr key={[suratKeluar.uuid]}>
                          <td>{suratKeluar.strJudulSurat}</td>
                          <td>{suratKeluar.strNoSurat}</td>
                          <td>
                            {moment(suratKeluar.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                          </td>
                          <td>{suratKeluar.strSemester}</td>
                          <td>{suratKeluar.strTahunAjaran}</td>
                          <td>{suratKeluar.strPerihal}</td>
                          <td>{suratKeluar.strLampiran}</td>
                          <td>{suratKeluar.strStatus}</td>
                          <td>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul>
                          </td>
                          <td>
                            <a
                              href={suratKeluar.url}
                              onClick={(e) => download(e, suratKeluar.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Keluar
                            </a>
                          </td>

                          <td>{suratKeluar.strKomentar}</td>
                          <td>{suratKeluar.strKomentarKetua}</td>
                          <td>{suratKeluar.strKomentarSE}</td>
                          <td>{suratKeluar.strTembusan}</td>
                          <td>{suratKeluar.user.strNama}</td>
                          <td>
                            {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                            <a
                              href={`/surat-keluar/edit/${suratKeluar.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-keluar"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratKeluar(suratKeluar.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

                {user &&
                  user.strRole === "Supervisor Qiraati" &&
                  (user.strNama === "Pera Musika, M.Pd") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("Supervisor Qiraati") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user &&

                  (user.strNama === "Sarpani" ||
                    user.strNama === "Leo Julhamsyah SMPI" || user.strNama === "Zulfikar, M.Pd") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SMPI Al Hasanah") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}





                {user && (user.strRole === "Staff TU" &&
                  user.strNama === "Yudha Dwitiawanty") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes("PAUD IT Al Hasanah 1")) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user && (user.strRole === "Kepala Sekolah" &&
                  user.strNama === "Syamsidar,S.Pd.I") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes("PAUD IT Al Hasanah 1")
                          ) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user && (user.strRole === "Staff TU" &&
                  user.strNama === "Yudha Dwitiawanty2") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes("PAUD IT Al Hasanah 2")) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user && (user.strRole === "Kepala Sekolah" &&
                  user.strNama === "Aulia Rahmi") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes("PAUD IT Al Hasanah 2")
                          ) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}



                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Mubaroq Al Machzumi, S.Pd.I." && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SMAIT Al Hasanah") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Staff TU" &&
                  (user.strNama === "Leo Julhamsyah") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SDIT Al Hasanah 2") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}



                {user &&
                  user.strRole === "Kepala Sekolah" &&
                  (user.strNama === "Rian Ramadhanu, S.Pd.,Gr") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SDIT Al Hasanah 2") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user &&
                  user.strRole === "Kepala Sekolah" &&
                  (user.strNama === "Muhammad Barid, M.Pd.I" || user.strNama === "Deri Fachri Hasymi, S.Pi") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("Pondok Pesantren") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Tri Putri Lestari" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("Pondok Pesantren") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}



                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Sandra Yesi" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes(
                            "TPQ SDIT Al Hasanah 1"
                          ) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Reno Agung Laksono" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("TPQ SMPI Al Hasanah") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Yokkie Gustiany" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes(
                            "TPQ PAUDIT Al Hasanah 1"
                          ) ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Anita" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("TPQ SIT 2 Al Hasanah") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Staff TU" &&
                  user.strNama === "Rama Oji Mahyudhani" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("TPQ Pondok Pesantren") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Staff TU" &&
                  (user.strNama === "Jefrima Dwi Putra" ||
                    user.strNama === "Defri Haryanto") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SDIT Al Hasanah 1") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}


                {user &&
                  user.strRole === "Kepala Sekolah" &&
                  (user.strNama === "Sri Wahyuni, S.Pd.,Gr.") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("SDIT Al Hasanah 1") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}




                {user &&
                  user.strRole === "Staff TU" &&
                  (user.strNama === "Husni" || user.strNama === "Intan") && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          suratKeluar.strTujuan.includes("Manajer Bisnis") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>
                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Kabid Sarpras, IT dan Humas" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes(
                            "Kabid Sarpras, IT dan Humas"
                          ) || suratKeluar.strTembusan === "Kabid Sarpras, IT dan Humas") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user &&
                  user.strRole === "Kabid Pendidikan dan Penjamin Mutu" && (
                    <tbody>
                      {filteredSuratKeluars.map((suratKeluar, index) =>
                        (suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                          suratKeluar.strStatus === "ACC Sekretaris Eksekutif") &&
                          (suratKeluar.strTujuan.includes(
                            "Kabid Pendidikan dan Penjamin Mutu"
                          ) || suratKeluar.strTembusan === "Kabid Pendidikan dan Penjamin Mutu") ? (
                          <tr key={[suratKeluar.uuid]}>
                            <td>{suratKeluar.strJudulSurat}</td>
                            <td>{suratKeluar.strNoSurat}</td>
                            <td>
                              {moment(suratKeluar.dateTglSurat).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>
                              {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                            </td>
                            <td>{suratKeluar.strSemester}</td>
                            <td>{suratKeluar.strTahunAjaran}</td>
                            <td>{suratKeluar.strPerihal}</td>
                            <td>{suratKeluar.strLampiran}</td>
                            <td>{suratKeluar.strStatus}</td>
                            <td>
                              {/* <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul> */}
                              -
                            </td>

                            <td>
                              <a
                                href={suratKeluar.url}
                                onClick={(e) => download(e, suratKeluar.strOriginalName)}
                                target="_blank"
                                rel="noreferrer"
                                style={{ marginRight: "7px" }}
                              >
                                <i className="fa fa-download" />
                                Surat Keluar
                              </a>
                            </td>

                            <td>-</td>
                            <td>-</td>
                            {/* <td>-</td> */}
                            <td>{suratKeluar.strTembusan}</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  )}

                {user && user.strRole === "Ketua Yayasan" && (
                  <tbody>
                    {filteredSuratKeluars.map((suratKeluar, index) =>
                      suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Ketua Yayasan" ||
                        suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                        suratKeluar.strStatus === "Revisi Ketua Yayasan" ||
                        suratKeluar.strStatus === "Ditolak Ketua Yayasan" ? (
                        <tr key={[suratKeluar.uuid]}>
                          <td>{suratKeluar.strJudulSurat}</td>
                          <td>{suratKeluar.strNoSurat}</td>
                          <td>
                            {moment(suratKeluar.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                          </td>
                          <td>{suratKeluar.strSemester}</td>
                          <td>{suratKeluar.strTahunAjaran}</td>
                          <td>{suratKeluar.strPerihal}</td>
                          <td>{suratKeluar.strLampiran}</td>
                          <td>{suratKeluar.strStatus}</td>
                          <td>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul>
                          </td>

                          <td>
                            <a
                              href={suratKeluar.url}
                              onClick={(e) => download(e, suratKeluar.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Keluar
                            </a>
                          </td>

                          <td>{suratKeluar.strKomentar}</td>
                          <td>{suratKeluar.strKomentarKetua}</td>
                          <td>{suratKeluar.strKomentarSE}</td>
                          <td>{suratKeluar.strTembusan}</td>
                          <td>{suratKeluar.user.strNama}</td>
                          <td>
                            {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                            <a
                              href={`/surat-keluar/edit/${suratKeluar.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-keluar"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratKeluar(suratKeluar.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}



                {user && user.strRole === "Sekretaris Eksekutif" && (
                  <tbody>
                    {filteredSuratKeluars.map((suratKeluar, index) =>
                      suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "ACC Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Ditolak Sekretaris Eksekutif" ? (
                        <tr key={[suratKeluar.uuid]}>
                          <td>{suratKeluar.strJudulSurat}</td>
                          <td>{suratKeluar.strNoSurat}</td>
                          <td>
                            {moment(suratKeluar.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                          </td>
                          <td>{suratKeluar.strSemester}</td>
                          <td>{suratKeluar.strTahunAjaran}</td>
                          <td>{suratKeluar.strPerihal}</td>
                          <td>{suratKeluar.strLampiran}</td>
                          <td>{suratKeluar.strStatus}</td>
                          <td>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul>
                          </td>

                          <td>
                            <a
                              href={suratKeluar.url}
                              onClick={(e) => download(e, suratKeluar.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Keluar
                            </a>
                          </td>

                          <td>{suratKeluar.strKomentar}</td>
                          <td>{suratKeluar.strKomentarKetua}</td>
                          <td>{suratKeluar.strKomentarSE}</td>
                          <td>{suratKeluar.strTembusan}</td>
                          <td>{suratKeluar.user.strNama}</td>
                          <td>
                            {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                            <a
                              href={`/surat-keluar/edit/${suratKeluar.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-keluar"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratKeluar(suratKeluar.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}

                {user && user.strRole === "Kabid Kepegawaian" && (
                  <tbody>
                    {filteredSuratKeluars.map((suratKeluar, index) =>
                      suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Ketua Yayasan" ||
                        suratKeluar.strStatus ===
                        "Pengajuan Kabid ke Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Kabid Kepegawaian" ||
                        suratKeluar.strStatus === "Pengajuan Surat Staff MNJ" ||
                        suratKeluar.strStatus === "ACC Ketua Yayasan" ||
                        suratKeluar.strStatus === "ACC Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Revisi Ketua Yayasan" ||
                        suratKeluar.strStatus === "Revisi Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Ditolak Sekretaris Eksekutif" ||
                        suratKeluar.strStatus === "Ditolak Ketua Yayasan" ? (
                        <tr key={[suratKeluar.uuid]}>
                          <td>{suratKeluar.strJudulSurat}</td>
                          <td>{suratKeluar.strNoSurat}</td>
                          <td>
                            {moment(suratKeluar.dateTglSurat).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {suratKeluar.dateTglSuratFix !== null ? moment(suratKeluar.dateTglSuratFix).format("DD/MM/YYYY") : "-"}
                          </td>
                          <td>{suratKeluar.strSemester}</td>
                          <td>{suratKeluar.strTahunAjaran}</td>
                          <td>{suratKeluar.strPerihal}</td>
                          <td>{suratKeluar.strLampiran}</td>
                          <td>{suratKeluar.strStatus}</td>
                          <td>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {suratKeluar.strTujuan.replace(/["[\]]/g, " ")}
                            </ul>
                          </td>

                          <td>
                            <a
                              href={suratKeluar.url}
                              onClick={(e) => download(e, suratKeluar.strOriginalName)}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginRight: "7px" }}
                            >
                              <i className="fa fa-download" />
                              Surat Keluar
                            </a>
                          </td>

                          <td>{suratKeluar.strKomentar}</td>
                          <td>{suratKeluar.strKomentarKetua}</td>
                          <td>{suratKeluar.strKomentarSE}</td>
                          <td>{suratKeluar.strTembusan}</td>
                          <td>{suratKeluar.user.strNama}</td>
                          <td>
                            {/* <a
                        href="as"
                        className="view"
                        title="View"
                        data-toggle="tooltip"
                        style={{ color: "#10ab80" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a> */}
                            <a
                              href={`/surat-keluar/edit/${suratKeluar.uuid}`}
                              className="edit"
                              title="Edit"
                              data-toggle="tooltip"
                              style={{ marginRight: "7px" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <a
                              href="/surat-keluar"
                              className="delete"
                              title="Delete"
                              data-toggle="tooltip"
                              style={{ color: "red" }}
                              onClick={() => deleteSuratKeluar(suratKeluar.uuid)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuratKeluar;
