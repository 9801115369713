import React, { useEffect, useState } from "react";
import Url from "../../global";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
import axios from "axios";
// import { useForm } from "react-hook-form";
import "../../GlobalvalidasiErorr.css";
import { useSelector } from "react-redux";

const EditSuratMasuk = () => {
  const [judulSurat, setJudulSurat] = useState("");
  const [noSurat, setNoSurat] = useState("");
  const [tujuan, setTujuan] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState("");
  const [tglSurat, setTglSurat] = useState("");
  const [tglSuratFix, setTglSuratFix] = useState("");
  const [semester, setSemester] = useState("");
  const [perihal, setPerihal] = useState("");
  const [lampiran, setLampiran] = useState("");
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState("");
  const [komentar, setKomentar] = useState("");
  const [komentarKetua, setKomentarKetua] = useState("");
  const [komentarSE, setKomentarSE] = useState("");
  const [komentarStaffMNJ, setKomentarStaffMNJ] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [tembusan, setTembusan] = useState("");
  const [disposisi, setDisposisi] = useState("");
  const [disposisiSE, setDisposisiSE] = useState("");


  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [file, setFile] = useState("");
  const [setError] = useState(null);
  const [setFileUrl] = useState("");

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed src={file} type="application/pdf" width="80%" height="600px" />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  useEffect(() => {
    const getSuratMasukById = async () => {
      try {
        const response = await axios.get(`${Url}/suratMasuk/${id}`);
        setJudulSurat(response.data.strJudulSurat);
        setTglSurat(response.data.dateTglSurat);
        setTglSuratFix(response.data.dateTglSuratFix);
        setSemester(response.data.strSemester);
        setPerihal(response.data.strPerihal);
        setLampiran(response.data.strLampiran);
        setStatus(response.data.strStatus);
        setNoSurat(response.data.strNoSurat);
        setTujuan(response.data.strTujuan);
        setTahunAjaran(response.data.strTahunAjaran);
        setKomentar(response.data.strKomentar);
        setKomentarStaffMNJ(response.data.strKomentarStaffMNJ);
        setKomentarKetua(response.data.strKomentarKetua);
        setKomentarSE(response.data.strKomentarSE);
        setKeterangan(response.data.strKeterangan);
        setTembusan(response.data.strTembusan)
        setDisposisi(response.data.strDisposisi)
        setDisposisiSE(response.data.strDisposisiSE)
        setFile(response.data.url);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getSuratMasukById();
  }, [id]);

  const updateSuratMasuk = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${Url}/suratMasuk/${id}`,
        {
          strJudulSurat: judulSurat,
          strNoSurat: noSurat,
          strTahunAjaran: tahunAjaran,
          strTujuan: tujuan,
          dateTglSurat: tglSurat,
          dateTglSuratFix: tglSuratFix,
          strSemester: semester,
          strPerihal: perihal,
          strLampiran: lampiran,
          strStatus: status,
          strKomentar: komentar,
          strKomentarStaffMNJ: komentarStaffMNJ,
          strKomentarKetua: komentarKetua,
          strKomentarSE: komentarSE,
          strTembusan: tembusan,
          strDisposisi: disposisi,
          strDisposisiSE: disposisiSE,
          url: file,
          strKeterangan: keterangan,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      navigate("/surat-masuk");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div className="container ">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-bSuratody rounded">
        <div
          className=" offset-sm-5 mt-5 mb-4 text-gred"
          style={{ color: "blue" }}
        >
          <h2>
            <b>Edit Surat Masuk</b>
          </h2>
        </div>
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <form className="box" onSubmit={updateSuratMasuk}>
                <p className="has-text-centered">{msg}</p>

                <div className="field">
                  <label className="label">Judul Surat</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={judulSurat || ''}
                        onChange={(e) => setJudulSurat(e.target.value)}
                        required
                      >
                        <option>------Pilih------</option>
                        <option value="Surat Pernyataan">
                          Surat Pernyataan
                        </option>
                        <option value="Surat Pemohonan">Surat Pemohonan</option>
                        <option value="Surat Pemesanan Buku">
                          Surat Pemesanan Buku
                        </option>
                        <option value="Surat Hasil Microteaching">
                          Surat Hasil Microteaching
                        </option>
                        <option value="Surat Cuti">Surat Cuti</option>
                        <option value="Surat Pemberitahuan">
                          Surat Pemberitahuan
                        </option>
                        <option value="Surat Undangan">Surat Undangan</option>
                        <option value="Surat Laporan">Surat Laporan</option>
                        <option value="Surat Teguran">Surat Teguran</option>
                        <option value="Surat Biodata Guru dan Karyawan">
                          Surat Biodata Guru dan Karyawan
                        </option>
                        <option value="Surat Jadwal Piket Satpam">
                          Surat Jadwal Piket Satpam
                        </option>
                        <option value="Surat Pinjaman Koperasi Gukar">
                          Surat Pinjaman Koperasi Gukar
                        </option>
                        <option value="Surat Pengunduran Diri dari Yayasan">
                          Surat Pengunduran Diri dari Yayasan
                        </option>
                        <option value="Surat Pengunduran Diri dari Koperasi">
                          Surat Pengunduran Diri dari Koperasi
                        </option>
                        <option value="Surat Rekomendasi Kontrak Guru">
                          Surat Rekomendasi Kontrak Guru
                        </option>
                        <option value="Surat Pengajuan">Surat Pengajuan</option>
                        <option value="Surat Pemberitahuan">
                          Surat Pemberitahuan
                        </option>
                        <option value="Surat Keputusan">Surat Keputusan</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Nomor Surat</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="Nomor Surat"
                      value={noSurat}
                      onChange={(e) => setNoSurat(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Kirim Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedatetime" className="dtp">
                      <Form.Control
                        type="datetime-local"
                        name="duedatetime"
                        placeholder="Tanggal Kirim Surat"
                        value={tglSurat}
                        onChange={(e) => setTglSurat(e.target.value)}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedate" className="dtp">
                      <Form.Control
                        type="date"
                        name="duedate"
                        placeholder="Tanggal Surat"
                        value={tglSuratFix}
                        onChange={(e) => setTglSuratFix(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Semester</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={semester || ''}
                        onChange={(e) => setSemester(e.target.value)}
                        required
                      // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>Semester 1</option>
                        <option>Semester 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tahun Ajaran</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tahunAjaran || ''}
                        onChange={(e) => setTahunAjaran(e.target.value)}
                        required
                      // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>2023</option>
                        <option>2024</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Perihal</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="Perihal"
                      value={perihal}
                      onChange={(e) => setPerihal(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Lampiran</label>
                  <div className="control">
                    <input
                      type="number"
                      className="input"
                      placeholder="Lampiran Wajib di Isi"
                      value={lampiran}
                      onChange={(e) => setLampiran(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Status Surat Masuk</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      {user &&
                        (user.strRole === "Staff MNJ" ||
                          user.strRole === "Admin") && (
                          <select
                            className="dropdown"
                            value={status || ''}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option>Pengajuan Surat Staff TU</option>
                            <option>Pengajuan Surat Staff MNJ</option>
                            <option>Pengajuan Surat Kepala Sekolah</option>
                            <option>Ditolak Surat Staff MNJ</option>
                            <option>Pengajuan Kabid ke Ketua Yayasan</option>
                            <option>
                              Pengajuan Kabid ke Sekretaris Eksekutif
                            </option>
                            <option>ACC Kabid Kepegawaian</option>
                            <option>
                              ACC Kabid Pendidikan dan Penjamin Mutu
                            </option>
                            <option>
                              ACC Kabid Kabid Sarpras, IT dan Humas
                            </option>
                            <option>Elsa, Windi dan Septi Follow Up</option>
                            <option>Revisi Kabid Kepegawaian</option>
                            <option>ACC Ketua Yayasan</option>
                            <option>Revisi Ketua Yayasan</option>
                            <option>Ditolak Ketua Yayasan</option>
                            <option>ACC Sekretaris Eksekutif</option>
                            <option>Revisi Sekretaris Eksekutif</option>
                            <option>Ditolak Sekretaris Eksekutif</option>
                          </select>
                        )}

                      {user && user.strRole === "Kabid Sarpras, IT dan Humas" && (
                        <select
                          className="dropdown"
                          value={status || '' }
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>--Pilih--</option>
                          <option>ACC Kabid Sarpras, IT dan Humas</option>
                          <option>Ditolak Kabid Sarpras, IT dan Humas</option>
                        </select>
                      )}

                      {user && user.strRole === "Kabid Pendidikan dan Penjamin Mutu" && (
                        <select 
                          className="dropdown"
                          value={status || ''}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>--Pilih--</option>
                          <option>ACC Kabid Pendidikan dan Penjamin Mutu</option>
                          <option>Ditolak Kabid Pendidikan dan Penjamin Mutu</option>
                        </select>
                      )}

                      {user && user.strRole === "Kabid Kepegawaian" && (
                        <select
                          className="dropdown"
                          value={status || ''}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>ACC Kabid Kepegawaian</option>
                          <option>Pengajuan Surat Staff MNJ</option>
                          <option>Pengajuan Kabid ke Ketua Yayasan</option>
                          <option>
                            Pengajuan Kabid ke Sekretaris Eksekutif
                          </option>
                          <option>Elsa, Windi dan Septi Follow Up</option>
                          <option>Revisi Kabid Kepegawaian</option>
                          <option>ACC Ketua Yayasan</option>
                          <option>Revisi Sekretaris Eksekutif</option>
                          <option>Ditolak Sekretaris Eksekutif</option>
                        </select>
                      )}

                      {user && user.strRole === "Ketua Yayasan" && (
                        <select
                          className="dropdown"
                          value={status || ''}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Pengajuan Kabid</option>
                          <option>ACC Ketua Yayasan</option>
                          <option>Revisi Ketua Yayasan</option>
                          <option>Ditolak Ketua Yayasan</option>
                        </select>
                      )}

                      {user && user.strRole === "Sekretaris Eksekutif" && (
                        <select
                          className="dropdown"
                          value={status || ''}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Pengajuan Sekretaris Eksekutif</option>
                          <option>ACC Sekretaris Eksekutif</option>
                          <option>Revisi Sekretaris Eksekutif</option>
                          <option>Ditolak Sekretaris Eksekutif</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>

                {user && (user.strRole !== "Sekretaris Eksekutif" || user.strRole !== "Ketua Yayasan") && (
                  <div className="field">
                    <label className="label">Tujuan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className="dropdown"
                          value={tujuan || ''}
                          onChange={(e) => setTujuan(e.target.value)}
                          disabled={user.strRole === "Ketua Yayasan" || user.strRole === "Sekretaris Eksekutif"}
                        >
                          <option>Ketua Yayasan</option>
                          <option>Sekretaris Eksekutif</option>
                          <option>Kabid Kepegawaian</option>
                          <option>Kabid Pendidikan dan Penjamin Mutu</option>
                          <option>Kabid Sarpras, IT dan Humas</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}





                <div className="field">
                  <label className="label">Komentar Staff MNJ</label>
                  <div className="control">
                    {user &&
                      (user.strRole === "Staff MNJ" ||
                        user.strRole === "Admin") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarStaffMNJ || ''}
                          onChange={(e) => setKomentarStaffMNJ(e.target.value)}
                        />
                      )}

                    {user &&
                      (user.strRole === "Ketua Yayasan" ||
                        user.strRole === "Sekretaris Eksekutif" ||
                        user.strRole === "Kepala Sekolah" ||
                        user.strRole === "Kabid Kepegawaian"
                      ) && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarStaffMNJ || ''}
                          onChange={(e) => setKomentarStaffMNJ(e.target.value)}
                          disabled={true}
                        />
                      )}
                  </div>
                </div>


                <div className="field">
                  <label className="label">Komentar Kabid</label>
                  <div className="control">
                    {user && (user.strRole === "Kabid Kepegawaian" || user.strRole === "Kabid Sarpras, IT dan Humas" || user.strRole === "Kabid Pendidikan dan Penjamin Mutu") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar Kabid"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}

                    {user && (user.strRole === "Ketua Yayasan" || user.strRole === "Sekretaris Eksekutif") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar || ''}
                        onChange={(e) => setKomentar(e.target.value)}
                        disabled={true}
                      />
                    )}

                    {user && user.strRole === "Admin" && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar || ''}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}

                    {user && user.strRole === "Staff MNJ" && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar || ''}
                        onChange={(e) => setKomentar(e.target.value)}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>



                <div className="field">
                  <label className="label">Komentar Ketua Yayasan</label>
                  <div className="control">
                    {user && 
                      (user.strRole === "Kabid Kepegawaian" ||
                        user.strRole === "Ketua Yayasan" ||
                        user.strRole === "Admin") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarKetua || ''}
                          onChange={(e) => setKomentarKetua(e.target.value)}
                  
                        />
                      )}

                    {user &&
                      (user.strRole === "Staff MNJ" ||
                        user.strRole === "Sekretaris Eksekutif") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarKetua || ''}
                          onChange={(e) => setKomentarKetua(e.target.value)}
                          disabled={true}
                        />
                      )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Komentar Sekretaris Eksekutif</label>
                  <div className="control">
                    {user && (user.strRole === "Kabid Kepegawaian" || user.strRole === "Sekretaris Eksekutif" || user.strRole === "Admin") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar SE"
                        value={komentarSE || ''}
                        onChange={(e) => setKomentarSE(e.target.value)}
                      
                      />
                    )}

                    {user && (user.strRole === "Staff MNJ" || user.strRole === "Ketua Yayasan") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar SE"
                        value={komentarSE || ''}
                        onChange={(e) => setKomentarSE(e.target.value)}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>


                {user && (user.strRole === "Ketua Yayasan") && (
                  <div className="field">
                    <label className="label">Disposisi Ketua Yayasan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className="dropdown"
                          value={disposisi || ''}
                          onChange={(e) => setDisposisi(e.target.value)}
                        // disabled={user.strRole !== "Ketua Yayasan" || user.strRole !== "Sekretaris Eksekutif"}
                        >
                          <option>--Pilih--</option>
                          <option>Staff Manajemen</option>
                          <option>Kabid Kepegawaian</option>
                          <option>Kabid Pendidikan dan Penjamin Mutu</option>
                          <option>Kabid Sarpras, IT dan Humas</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {user && (user.strRole === "Sekretaris Eksekutif") && (
                  <div className="field">
                    <label className="label">Disposisi Sekretaris Eksekutif</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className="dropdown"
                          value={disposisiSE || ''}
                          onChange={(e) => setDisposisiSE(e.target.value)}
                        // disabled={user.strRole !== "Ketua Yayasan" || user.strRole !== "Sekretaris Eksekutif"}
                        >
                          <option>--Pilih--</option>
                          <option>Staff Manajemen</option>
                          <option>Kabid Kepegawaian</option>
                          <option>Kabid Pendidikan dan Penjamin Mutu</option>
                          <option>Kabid Sarpras, IT dan Humas</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}


                <label className="label">Keterangan Surat</label>
                <div className="control">
                  {user && user.strRole === "Kabid Kepegawaian" && (
                    <FormControl
                      as="textarea"
                      rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                      placeholder="keterangan Surat"
                      value={keterangan || ''}
                      onChange={(e) => setKeterangan(e.target.value)}
                    />
                  )}
                </div>

                <div className="field">
                  <label className="label">Tembusan</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tembusan || ''}
                        onChange={(e) => setTembusan(e.target.value)}
                        required
                      >
                        <option>--Pilih--</option>
                        <option>Kepala Sekolah</option>
                        <option>Supervisor Qiraati</option>
                        <option>Pimpinan Pondok</option>
                        <option>Kabid Kepegawaian</option>
                        <option>Kabid Pendidikan dan Penjamin Mutu</option>
                        <option>Kabid Sarpras, IT dan Humas</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="field">
                  <label className="label">Image</label>
                  <div className="control">
                    <div className="file">
                      <label className="file-label">
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handleChange}
                          name="file"
                        />
                        {/* <button type="submit">Upload</button> */}
                      </label>
                      {setError && <p className="error">{setError}</p>}
                    </div>
                    <br />
                    <PdfViewer file={file} />
                  </div>
                </div>

                <br />
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuratMasuk;
