
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice";
import SuratMasuk from "../component/SuratMasuk/SuratMasuk";
import Main from '../component/Main/Main';
import axios from "axios";
import Url from "../global.jsx";

const PageSuratMasukList = () => {
  const dispatch = useDispatch();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const checkSessionInterval = setInterval(async () => {
      try {
        const response = await axios.get(`${Url}/suratMasuk`, { withCredentials: true });
        if (response.data.redirect) {
          window.location.href = response.data.url; // Pengalihan langsung menggunakan window.location.href
        }
      } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.redirect) {
          window.location.href = error.response.data.url; // Redirect pengguna ke halaman login
        } else {
          console.error('Error:', error);
        }
      }
    }, 60000); // 1 menit dalam milidetik

    return () => clearInterval(checkSessionInterval); // Bersihkan interval saat komponen unmount
  }, []);

  useEffect(() => {
    if (isError) {
      window.location.href = "https://e-surat.alhasanah.sch.id/";
    }
  }, [isError]);

  return (
    <Main>
      <SuratMasuk />
    </Main>
  );
};

export default PageSuratMasukList;
