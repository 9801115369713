import React, { useState, useEffect } from "react";
import Url from "../../global";
import axios from "axios";
import moment from "moment";
import "../SuratMasuk/suratmasuk.css"

const LogActivity = () => {
    const [logActivitys, setLogActivitys] = useState([]);
    const [query, setQuery] = useState("");
    const [query2, setQuery2] = useState("");


    useEffect(() => {
        getLogActivity();
    }, []);

    const getLogActivity = async () => {
        const response = await axios.get(`${Url}/log_activitys`);
        setLogActivitys(response.data);
    };


    return (

        <div>
            <br />
            <h1 className="title">Riwayat Login Pengguna All Unit</h1>
            <br />

            <br />
            <label> Masukan Nama </label>
            <input
                className="search"
                placeholder="Cari Nama Individu"
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />

            <label className="label-unit">Masukan Unit </label>
            <input
                className="search"
                placeholder="Cari Berdasarkan Unit"
                onChange={(e) => setQuery2(e.target.value.toLowerCase())}
            />

            <div class="table__wrapper_Scroll_Log">
                <table
                    className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
                    id="table-to-xls"
                >
                    <thead>
                        <tr className="has-text-centered">
                            <th className="has-text-centered">No</th>
                            <th className="has-text-centered">Nama Pegawai</th>
                            <th className="has-text-centered">Unit</th>
                            <th className="has-text-centered">Record Login Per Waktu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logActivitys
                            .filter(
                                (logActivity) =>
                                    logActivity.strNamaAct.toLowerCase().includes(query) &&
                                    logActivity.strUnitAct.toLowerCase().includes(query2)
                            )
                            .map((logActivity, index) => (
                                <tr key={logActivity.uuid}>
                                    <td className="has-text-centered" width="5px">
                                        {index + 1}
                                    </td>
                                    <td className="has-text-centered" width="30px">
                                        {logActivity.strNamaAct}
                                    </td>
                                    <td className="has-text-centered" width="50px">
                                        {logActivity.strUnitAct}
                                    </td>
                                    <td className="has-text-centered" width="50px">
                                        {moment(logActivity.dateTglLogin).format(
                                            "DD/MM/YYYY HH:mm"
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default LogActivity