import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Dashboard from "../component/Dashboard/Dashboard";
import Main from "../component/Main/Main";
import axios from "axios";
import Url from "../global.jsx";
import { getMe } from "../features/authSlice";

const PageDashboard = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const checkSessionInterval = setInterval(async () => {
      try {
        const response = await axios.get(`${Url}/suratMasuk`, {
          withCredentials: true,
        });
        if (response.data.redirect) {
          window.location.href = response.data.url; // Redirect pengguna ke halaman login jika sesi tidak valid
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.redirect
        ) {
          window.location.href = error.response.data.url; // Redirect pengguna ke halaman login jika sesi tidak valid
        } else {
          console.error("Error:", error);
        }
      }
    }, 60000); // 1 menit dalam milidetik

    return () => clearInterval(checkSessionInterval); // Bersihkan interval saat komponen unmount
  }, []);

  useEffect(() => {
    if (isError) {
      window.location.href = "https://e-surat.alhasanah.sch.id/";
    }
  }, [isError]);

  return (
    <Main>
      <Dashboard />
    </Main>
  );
};

export default PageDashboard;
